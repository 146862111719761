import * as d3 from "d3"
import { select } from "d3"
import React, { useLayoutEffect, useRef } from "react"
import Layout from "../components/containers/layout"
import Seo from "../components/containers/seo"
import { getRoute, routes, ROUTES_ID } from "../constants/routes.contants"

const hasTreeNode = _routes =>
  _routes.map(route => ({ name: route.screenTitle, href: route.path }))

const fromMenu = hasTreeNode(routes.filter(route => route.id < 100))
const other = hasTreeNode(routes.filter(route => route.id >= 100))

const data = {
  name: "/",
  children: [
    {
      name: "Menu",
      children: fromMenu,
    },
    ...other,
  ],
}

const width = 954

const tree = data => {
  const root = d3.hierarchy(data)
  root.dx = 30
  root.dy = width / (root.height + 1)
  return d3.tree().nodeSize([root.dx, root.dy])(root)
}

const chart = ref => {
  const root = tree(data)

  let x0 = Infinity
  let x1 = -x0
  root.each(d => {
    if (d.x > x1) x1 = d.x
    if (d.x < x0) x0 = d.x
  })

  const svg = select(ref.current).attr("viewBox", [
    0,
    0,
    width,
    x1 - x0 + root.dx * 2,
  ])

  const g = svg
    .append("g")
    .attr("font-family", "sans-serif")
    .attr("font-size", 10)
    .attr("transform", `translate(${root.dy / 3},${root.dx - x0})`)

  g.append("g")
    .attr("fill", "none")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5)
    .selectAll("path")
    .data(root.links())
    .join("path")
    .attr("stroke", d => (d.source.depth % 2 === 0 ? "#008D89" : "#000"))
    .attr(
      "d",
      d3
        .linkHorizontal()
        .x(d => d.y)
        .y(d => d.x)
    )

  const node = g
    .append("g")
    .attr("stroke-linejoin", "round")
    .attr("stroke-width", 3)
    .selectAll("g")
    .data(root.descendants())
    .join("g")
    .attr("transform", d => `translate(${d.y},${d.x})`)

  node
    .append("circle")
    .attr("fill", d => (d.children ? "#555" : "#999"))
    .attr("r", 2.5)

  node
    .append("a")
    .attr("xlink:href", d => d.data.href)
    .attr("onClick", function (d) {
      return () => console.log(window)
      // window.gtag("event", "navigation", {
      //   event_category: "page-content",
      //   event_label: d.data.name,
      // })
    })
    .append("text")
    .attr("x", d => (d.children ? -6 : 6))
    .attr("dy", "0.31em")
    .attr("text-anchor", d => (d.children ? "end" : "start"))
    .text(d => d.data.name)
    .clone(true)
    .lower()
    .attr("stroke", "white")
    .append("circle")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", 100)
    .attr("height", 10)
    .style("stroke", "blue")
    .style("fill", "none")
    .style("stroke-width", "2px")

  return svg.node()
}

const MentionsLegals = () => {
  const route = getRoute(ROUTES_ID.CONFIDENTIALITE)
  const ref = useRef(null)
  useLayoutEffect(() => {
    chart(ref)
  })
  return (
    <>
      <Seo title={route.screenTitle} />
      <Layout>
        <h2>Plan du site</h2>
        <svg ref={ref}></svg>
      </Layout>
    </>
  )
}

export default MentionsLegals
